$(function () {
    $('[data-toggle="offcanvas"]').on('click', function () {
        $('.offcanvas-collapse').toggleClass('open')
    });
    $('.navbar-close').on('click', function() {
        $('.offcanvas-collapse').toggleClass('open')
    });
})

$(function () {
    var sCtrSearchInactive = 'blurred';
    var sBtnSearchActive = 'search__btn-active';

    var $searchCtr = $('.search__ctr'),
        $searchBtn = $('.search__btn');

    $('.search__close').on('click', function () {
        $searchCtr.addClass(sCtrSearchInactive)
        $searchBtn.removeClass(sBtnSearchActive)
    })

    $searchBtn.on('click', function () {
        if ($searchCtr.hasClass(sCtrSearchInactive)) {
            // expand search
            $searchCtr.removeClass((sCtrSearchInactive));
            $searchBtn.addClass(sBtnSearchActive);
        } else {
            // perform search
            $searchCtr.parent('form').submit()
        }
    })
});

$(function () {
    var $expandBtns = $('.expand-mobile-menu');

    $expandBtns.click(function() {
        var $this = $(this);
        var $parent = $this.parent();

        $this.toggleClass('active');
        $parent.toggleClass('active');

        if($this.hasClass('active')) {
            // Expand menu
            $parent.find('img').not($this.children()).prop('src', 'https://www.lls.nsw.gov.au/__data/assets/git_bridge/0020/1153325/dist/images/arrow-down.svg');
            $this.children('img').prop('src', 'https://www.lls.nsw.gov.au/__data/assets/git_bridge/0020/1153325/dist/images/arrow-up-blue.svg');
            $parent.siblings().find('.mobile-sub-nav--expand')
                .not($this.siblings('.dropdown-menu__display-toggle'))
                .removeClass('mobile-sub-nav--expand');
            $this.siblings('.dropdown-menu__display-toggle').addClass("mobile-sub-nav--expand");
            $parent.siblings('li').hide().removeClass('d-flex');

            if ($this.hasClass('expand__lev2')) {
                $this.parents('.megamenu-grid__box').siblings().hide();
            }

        } else {
            // Close menu
            $this.children('img').prop('src', 'https://www.lls.nsw.gov.au/__data/assets/git_bridge/0020/1153325/dist/images/arrow-down.svg');
            $parent.find('.mobile-sub-nav--expand').removeClass('mobile-sub-nav--expand');
            $parent.parent().find('li').show().addClass('d-flex');
            $parent.find('.active').removeClass('active');

            if ($this.hasClass('expand__lev2')) {
                $this.parents('.megamenu-grid__box').siblings().show();
            } else {
                $parent.find('.megamenu-grid__box').show();
            }
        }
    })
})


